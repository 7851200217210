.wrapper {
  &:global(.is-article) {
    @apply pt-0 !important;
  }

  & :global(.inner-wrapper) {
    @apply box-content px-4 mx-auto md:px-8 max-w-8xl;
  }

  &:global(.is-faq .inner-wrapper) {
    @apply px-0;
  }

  &:global(.bg-color-summer-green) {
    @apply bg-summer-green-25;
    @apply py-10 md:py-16;
  }

  &:global(.bg-color-green) {
    @apply bg-green-25;
    @apply py-10 md:py-16;
  }

  &:global(.bg-color-white) {
    @apply bg-white;
    @apply py-10 md:py-16;
  }
}

.item {
  @apply max-w-1xl;

  & header {
    @apply flex justify-between items-center pb-4;
    & h2 {
      @apply font-sans text-xl;
    }
  }

  & :global(.toggle) {
    @apply flex items-center justify-center flex-none bg-summer-green-100 w-8 h-8 cursor-pointer;

    & svg {
      @apply w-6 h-auto transition-transform ease-in-out duration-75;
      &:global(.is-open) {
        @apply transform rotate-180;
      }
    }
  }
}
