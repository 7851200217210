.map {
  @apply overflow-hidden;

  & :global(.google-map) {
    @apply bg-black-100/10;
  }

  & :global(.spinner) {
    @apply flex items-center justify-center w-full h-full;
  }

  & :global(.small) {
    @apply max-w-3xl;
  }

  & :global(.large) {
    @apply max-w-5xl;
  }

  & :global(.full) {
    @apply w-full;
  }

  & :global(.format-16-9) {
    @apply aspect-w-16 aspect-h-9;
  }

  & :global(.format-4-3) {
    @apply aspect-w-4 aspect-h-3;
  }

  & :global(.format-1-1) {
    @apply aspect-w-1 aspect-h-1;
  }

  & img {
    @apply w-full h-auto;
  }

  & iframe {
    @apply mx-auto;
  }
}
