.wrapper {
  @apply flex items-center mb-4 md:mb-6 lg:mb-8 text-black-100;

  & :global(.icon) {
    @apply inline-flex bg-summer-green-100 rounded-full justify-center items-center mr-4;
    @apply w-14 h-14 md:w-20 md:h-20 flex-grow-0 flex-shrink-0;

    & svg {
      @apply w-8 h-8 md:w-12 md:h-12;
    }
  }

  & :global(.lead) {
    @apply max-w-1xl;
  }

  &:global(.no-icon) {
    @apply block;

    & h2 {
      @apply mb-3;
    }
  }

  &:global(.center) {
    & h2,
    & :global(.lead) {
      @apply mx-auto text-center;
    }
  }

  &:global(.inner-column) {
    @apply max-w-1xl mx-auto;
  }
}
