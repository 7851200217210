.card {
  & :global(.image-wrapper) {
    @apply aspect-w-4 aspect-h-3 relative w-full mb-3.6 overflow-hidden bg-black-100/10;

    & :global(.icon) {
      @apply w-full h-full flex justify-center items-center;

      & svg {
        @apply w-36 h-auto mb-1 fill-black opacity-10;
      }
    }

    & img {
      @apply image-has-hover-effect;
    }

    &:hover img {
      @apply image-hover-effect;
    }
  }

  & :global(.has-detail) {
    @apply cursor-pointer;
  }

  & :global(.text-wrapper) {
    @apply text-base md:text-lg;
  }

  & h3 {
    @apply mb-2;
  }

  & button {
    @apply block underline text-green-100 font-bold;
  }

  & a {
    @apply inline-block text-green-100 font-bold underline;
  }

  & span {
    @apply block pb-2;
  }
}
