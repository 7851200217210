.wrapper {
  &:global(.align-center) {
    @apply text-center;
  }

  & :global(.block-header) {
    @apply mb-8;
  }

  & :global(.heading) {
    @apply text-2xl mb-4;
  }

  & ol {
    @apply mb-4;
  }

  & li {
    @apply mb-4;
  }

  & a {
    @apply underline;
  }
}
