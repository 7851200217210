.files {
  & :global(.inner-wrapper) {
    @apply box-content px-4 mx-auto md:px-8 max-w-1xl;
  }

  &:global(.bg-color-summer-green) {
    @apply bg-summer-green-25;
    @apply py-10 md:py-16;
  }

  &:global(.bg-color-green) {
    @apply bg-green-25;
    @apply py-10 md:py-16;
  }

  &:global(.bg-color-white) {
    @apply bg-white;
    @apply py-10 md:py-16;
  }

  & ul {
    @apply m-0 p-0;
  }
}

.file {
  @apply flex items-center mb-4;

  & a {
    @apply underline block -mt-1;
  }

  & span {
    @apply block text-xs text-black-100/80;
  }

  & :global(.icon) {
    @apply w-8 h-8 flex justify-center items-center bg-summer-green-100 mr-4;
    @apply flex-none rounded;
  }

  & svg {
    @apply w-4 h-4;
  }
}
