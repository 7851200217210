.wrapper {
  & :global(.columns) {
    @apply box-content px-4 mx-auto md:px-8 max-w-8xl;
    @apply grid grid-cols-1 lg:grid-cols-2 gap-x-12 gap-y-6;

    & a {
      @apply underline;
    }

    & :global(.no-text) {
      @apply mb-0;
    }

    & :global(> div .inner-column),
    & :global(> div .accordion) {
      @apply max-w-none;
    }
  }

  &:global(.bg-color-summer-green) {
    @apply bg-summer-green-25;
    @apply py-10 md:py-16;
  }

  &:global(.bg-color-green) {
    @apply bg-green-25;
    @apply py-10 md:py-16;
  }

  &:global(.bg-color-white) {
    @apply bg-white;
    @apply py-10 md:py-16;
  }
}
